@blue: #0690ED;
@blue-hover: #d6edfc;
@grey-outline: #bfbfbf;
@box-radius: 2px;

html {
  height:100%;
}

body {
  height:100%;
  @media print {
    width: 700px;
    max-width: 700px;
  }
}


#root {
  height:100%;

  &.print {
    width: 1100px;
    max-width: 1100px;
  }
}

.tabContainer {
  display:flex;
  z-index: 100;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display:flex;
  height: 50px;
  align-items: center;
}

.headerButton {
  cursor:pointer;
  margin:5px 20px;
  font-family: 'Source Sans Pro';
  color:#646464;
  font-size:18px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #ffa300;
  }

  &.selected {
    font-weight: 700;
    border-bottom: 2px solid #ffa300;
  }
}

.v--modal {
  border-radius:10px !important
}

.helpModal {
  .helpText {
    font-size: 14px;
    color: #585858;
    header {
      font-weight:bold;
      color: black;
      font-size: 16px;
    }

    .additional {
      font-style: italic;
      margin-top:40px;
    }
  }

  .navButton {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.buttonGroup {
  display:flex;

  &.vertical {
    flex-direction: column;
    .button {
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
      &:not(first-child) {
        margin-top: -1px;
      }
    }
  }

  &:not(.vertical) {
    .button {
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &:not(first-child) {
        margin-left: -1px;
      }
    }
  }

  .button {
    padding: 3px 12px;
    border: 1px solid @blue;
    cursor: pointer;
    color: @blue;

    &.selected {
      background: @blue;
      color:white;
      &:hover {
        background: @blue;
      }
    }

    &:hover {
      background: @blue-hover;
    }

    &.disabled {
      color:lightgray;
      border-color:lightgray;

      &:hover {
        background-color:inherit;
        cursor:not-allowed;
      }
    }
  }
}

.tab {
  padding:10px;
  flex-grow: 1;
  display:flex;
  &:focus {
    outline: none;
  }
}
.agpDays {
  .agpDay {
    border: 1px solid @grey-outline;
    border-radius: @box-radius;
    padding: 1px 2px;
    margin-left: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      background: @blue-hover;
    }

    &.highlighted {
      background: @blue-hover;
    }

    .agpBar {
      height: 5px;
      display: flex;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 1px;
    }
  }

  .headerContainer {
    .header {
      color: #b8b8b8;
      margin-left: 5px;
      cursor: pointer;
      text-align: center;
      border-radius: @box-radius;
      background-color: rgba(144, 144, 144, 0.05);
      color: #909090;
      border: 1px solid transparent;

      &.selected {
        background-color: rgba(6, 144, 237, 0.15);
        color: #0690ed;
      }

      &:hover {
        border: 1px solid @grey-outline;
      }
    }
  }
}

.box {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: white;
  box-shadow: #dfdfdf 2px 2px 4px;
}

.timeInRangeBar {
  display: flex;
  flex-direction: column;
}

.dailyLogMiniatures {
  margin-right: 10px;

  .dailyLogMiniature{
    display:flex;
    flex-direction: column;
    padding:5px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background: @blue-hover;
    }

    .graphAndTIR {
      margin-top:3px;
      display:flex;
    }
  }
}

.dailyLogItemsContainer {
  position: relative;
  flex-grow:1;

  .dailyLogItems {
    position: absolute;
    top:-10px;
    bottom:-10px;
    left:0;
    right:-10px;
    overflow-y: scroll;

    .dailyLogItem {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      flex-direction: row;
      margin-top:5px;
      margin-right:10px;

      &.flash {
        animation: boxflash 1.5s;
        animation-iteration-count: 1;
      }

      &:last-child {
        margin-bottom: 10px;
      }

      &:first-child {
        margin-top: 10px;
      }

      .dailyStatistics {
        margin: 10px;
        display: flex;
      }
    }
  }
}

@keyframes boxflash {
  from, 60% {background-color: #e7e7e7;}
  to {background-color: white;}
}
.statistics {
  flex-grow:0;
  flex-shrink:0;
  width:320px;
  margin-left:10px;
  position:relative;
  transition: width 0.2s;

  &.collapsed {
    width: 36px;

    .summary {
      opacity: 1;
    }

    .clipContainer {
      opacity: 0;
    }

    .collapseButton {
      i {
        transform: rotate(180deg);
      }
    }
  }

  .summary {
    transform: rotate(90deg);
    position: absolute;
    top:80px;
    left: -13px;
    font-size: 16px;
    font-weight: bold;
    transition: opacity 0.2s;
    opacity: 0;
  }

  .clipContainer {
    transition: opacity 0.2s;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 1;
  }

  .collapseButton {
    width:30px;
    height:30px;
    border-radius: 20px;
    border: 1px solid #cdcdcd;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    position:absolute;
    display:flex;
    background: white;
    align-items: center;
    top: 5px;
    left: -10px;
    z-index: 10;
    cursor: pointer;

    &:hover {
      background: @blue-hover;
    }

    i {
      margin: auto;
      transition: transform 0.2s;
    }
  }

  .inactive {
    opacity: 0.15;
  }

  .block {
    margin: 10px 15px;

    &:not(:last-child) {
      padding-bottom:5px;
      border-bottom: 1px solid #dfdfdf;
    }
  }

  .header {
    font-size: 16px;
    margin-bottom: 5px;
    display:flex;
    align-items: center;

    &.expandable {
      cursor: pointer;
    }
  }

  .valueHeader {
    margin-bottom: 2px;
  }

  .value {
    margin-top:3px;
    margin-right:2px;
    font-size:18px;
    font-weight: bold;
  }

  .legendItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom:5px;
    }
  }

  .colorMarker {
    width:12px;
    height:12px;
    display:inline-block;
    margin-right:5px;
    border-radius: 6px;
  }

  .expandButton {
    margin-left: auto;
    //height: 19px;
    transition: transform 0.2s;
    //padding-bottom: 4px;

    &.expanded {
      transform: rotateX(180deg);
    }
  }

  .statisticsBody {
      transition: max-height 0.2s;
      overflow: hidden;
  }

  .pumpModeStatisticsBlock {
    &:not(:first-child) {
      border-top: 1px solid #dfdfdf;
      margin-top: 5;
      padding-top: 5;
    }
  }
}

.optionsBox {
  position:absolute;
  right:0;
  top:25px;
  background:white;
  border: 1px solid #b3b3b3;
  z-index: 10;

  div {
    margin:5px 10px 5px 0;
  }
}

.eventStatisticsRow {
  display:flex;
  flex-direction:row;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid @grey-outline;
    background: @blue-hover;
    border-radius: @box-radius
  }
}

.basalTable {
  margin-top:10px;
  margin-bottom:5px;

  .tableRow {
    background: #f1f1f1;
    display:flex;
    padding: 3px 10px;

    &:nth-child(even) {
      background: #f8f8f8;
    }

    .tableValue {
      margin-left: auto;
    }
  }
}

.barItem {
  margin-bottom: 1px;
  transition: height 0.2s;
}

.eventOverlayCalendar {
  .timeInterval {
    display:flex;
    margin-left:auto;
    margin-right:auto;
    flex-direction:column;
    padding-bottom:15px;

    &.active {
      &:hover {
        background: @blue-hover;
        cursor:pointer;
      }
    }
  }

  .day {
    margin:auto;
    border-radius:100px;
    width:25px;
    height:25px;
    border:2px solid transparent;
    display:flex;
    align-items:center;

    &.active {
      border-color: @blue;
      cursor:pointer;
      &:hover {
        background: @blue-hover;
      }
    }
  }

  .eventOverlayCalendarContainer {

    .eventOverlayCalendarItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top:5px;
      border: 1px solid @grey-outline;
      border-radius: 2px;
      padding: 1px 2px;
      &:hover {
        background: @blue-hover;
      }
      &.highlighted {
        background: @blue-hover;
      }
    }
  }
}

.errorMessage {
  display: flex;
  position: fixed;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  color:white;
  border-radius: 8px;
  padding:5px 10px;
  background:rgb(176,0,2);
  z-index:100;

  .message {
    margin-right: 10px;
  }

  .button {
    color:white;
    border-color:white;
  }
}

