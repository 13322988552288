html, body {
  height: 100%;
}

@media print {
  body {
    width: 700px;
    max-width: 700px;
  }
}

#root {
  height: 100%;
}

#root.print {
  width: 1100px;
  max-width: 1100px;
}

.tabContainer {
  z-index: 100;
  height: 50px;
  background-color: #fff;
  align-items: center;
  display: flex;
  box-shadow: 1px 2px 4px #00000040;
}

.headerButton {
  cursor: pointer;
  color: #646464;
  border-bottom: 2px solid #0000;
  align-items: center;
  margin: 5px 20px;
  font-family: Source Sans Pro;
  font-size: 18px;
  display: flex;
}

.headerButton:hover {
  border-bottom: 2px solid #ffa300;
}

.headerButton.selected {
  border-bottom: 2px solid #ffa300;
  font-weight: 700;
}

.v--modal {
  border-radius: 10px !important;
}

.helpModal .helpText {
  color: #585858;
  font-size: 14px;
}

.helpModal .helpText header {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

.helpModal .helpText .additional {
  margin-top: 40px;
  font-style: italic;
}

.helpModal .navButton {
  color: #00000080;
  font-size: 14px;
}

.helpModal .navButton:hover {
  color: #000000b3;
}

.buttonGroup {
  display: flex;
}

.buttonGroup.vertical {
  flex-direction: column;
}

.buttonGroup.vertical .button:first-child {
  border-radius: 4px 4px 0 0;
}

.buttonGroup.vertical .button:last-child {
  border-radius: 0 0 4px 4px;
}

.buttonGroup.vertical .button:not(first-child) {
  margin-top: -1px;
}

.buttonGroup:not(.vertical) .button:first-child {
  border-radius: 4px 0 0 4px;
}

.buttonGroup:not(.vertical) .button:last-child {
  border-radius: 0 4px 4px 0;
}

.buttonGroup:not(.vertical) .button:not(first-child) {
  margin-left: -1px;
}

.buttonGroup .button {
  cursor: pointer;
  color: #0690ed;
  border: 1px solid #0690ed;
  padding: 3px 12px;
}

.buttonGroup .button.selected {
  color: #fff;
  background: #0690ed;
}

.buttonGroup .button.selected:hover {
  background: #0690ed;
}

.buttonGroup .button:hover {
  background: #d6edfc;
}

.buttonGroup .button.disabled {
  color: #d3d3d3;
  border-color: #d3d3d3;
}

.buttonGroup .button.disabled:hover {
  background-color: inherit;
  cursor: not-allowed;
}

.tab {
  flex-grow: 1;
  padding: 10px;
  display: flex;
}

.tab:focus {
  outline: none;
}

.agpDays .agpDay {
  cursor: pointer;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  padding: 1px 2px;
  display: flex;
}

.agpDays .agpDay:hover, .agpDays .agpDay.highlighted {
  background: #d6edfc;
}

.agpDays .agpDay .agpBar {
  height: 5px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 1px;
  display: flex;
}

.agpDays .headerContainer .header {
  color: #b8b8b8;
  cursor: pointer;
  text-align: center;
  color: #909090;
  background-color: #9090900d;
  border: 1px solid #0000;
  border-radius: 2px;
  margin-left: 5px;
}

.agpDays .headerContainer .header.selected {
  color: #0690ed;
  background-color: #0690ed26;
}

.agpDays .headerContainer .header:hover {
  border: 1px solid #bfbfbf;
}

.box {
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #dfdfdf;
}

.timeInRangeBar {
  flex-direction: column;
  display: flex;
}

.dailyLogMiniatures {
  margin-right: 10px;
}

.dailyLogMiniatures .dailyLogMiniature {
  cursor: pointer;
  flex-direction: column;
  padding: 5px;
  display: flex;
}

.dailyLogMiniatures .dailyLogMiniature:not(:last-child) {
  margin-bottom: 5px;
}

.dailyLogMiniatures .dailyLogMiniature:hover {
  background: #d6edfc;
}

.dailyLogMiniatures .dailyLogMiniature .graphAndTIR {
  margin-top: 3px;
  display: flex;
}

.dailyLogItemsContainer {
  flex-grow: 1;
  position: relative;
}

.dailyLogItemsContainer .dailyLogItems {
  position: absolute;
  inset: -10px -10px -10px 0;
  overflow-y: scroll;
}

.dailyLogItemsContainer .dailyLogItems .dailyLogItem {
  flex-direction: row;
  flex: 1;
  margin-top: 5px;
  margin-right: 10px;
  display: flex;
}

.dailyLogItemsContainer .dailyLogItems .dailyLogItem.flash {
  animation: boxflash 1.5s;
}

.dailyLogItemsContainer .dailyLogItems .dailyLogItem:last-child {
  margin-bottom: 10px;
}

.dailyLogItemsContainer .dailyLogItems .dailyLogItem:first-child {
  margin-top: 10px;
}

.dailyLogItemsContainer .dailyLogItems .dailyLogItem .dailyStatistics {
  margin: 10px;
  display: flex;
}

@keyframes boxflash {
  from, 60% {
    background-color: #e7e7e7;
  }

  to {
    background-color: #fff;
  }
}

.statistics {
  width: 320px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;
  transition: width .2s;
  position: relative;
}

.statistics.collapsed {
  width: 36px;
}

.statistics.collapsed .summary {
  opacity: 1;
}

.statistics.collapsed .clipContainer {
  opacity: 0;
}

.statistics.collapsed .collapseButton i {
  transform: rotate(180deg);
}

.statistics .summary {
  opacity: 0;
  font-size: 16px;
  font-weight: bold;
  transition: opacity .2s;
  position: absolute;
  top: 80px;
  left: -13px;
  transform: rotate(90deg);
}

.statistics .clipContainer {
  opacity: 1;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.statistics .collapseButton {
  width: 30px;
  height: 30px;
  z-index: 10;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 20px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 5px;
  left: -10px;
  box-shadow: 1px 2px 4px #00000040;
}

.statistics .collapseButton:hover {
  background: #d6edfc;
}

.statistics .collapseButton i {
  margin: auto;
  transition: transform .2s;
}

.statistics .inactive {
  opacity: .15;
}

.statistics .block {
  margin: 10px 15px;
}

.statistics .block:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 5px;
}

.statistics .header {
  align-items: center;
  margin-bottom: 5px;
  font-size: 16px;
  display: flex;
}

.statistics .header.expandable {
  cursor: pointer;
}

.statistics .valueHeader {
  margin-bottom: 2px;
}

.statistics .value {
  margin-top: 3px;
  margin-right: 2px;
  font-size: 18px;
  font-weight: bold;
}

.statistics .legendItem {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.statistics .legendItem:not(:last-of-type) {
  margin-bottom: 5px;
}

.statistics .colorMarker {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 5px;
  display: inline-block;
}

.statistics .expandButton {
  margin-left: auto;
  transition: transform .2s;
}

.statistics .expandButton.expanded {
  transform: rotateX(180deg);
}

.statistics .statisticsBody {
  transition: max-height .2s;
  overflow: hidden;
}

.statistics .pumpModeStatisticsBlock:not(:first-child) {
  border-top: 1px solid #dfdfdf;
  margin-top: 5px;
  padding-top: 5px;
}

.optionsBox {
  z-index: 10;
  background: #fff;
  border: 1px solid #b3b3b3;
  position: absolute;
  top: 25px;
  right: 0;
}

.optionsBox div {
  margin: 5px 10px 5px 0;
}

.eventStatisticsRow {
  cursor: pointer;
  border: 1px solid #0000;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.eventStatisticsRow:hover {
  background: #d6edfc;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
}

.basalTable {
  margin-top: 10px;
  margin-bottom: 5px;
}

.basalTable .tableRow {
  background: #f1f1f1;
  padding: 3px 10px;
  display: flex;
}

.basalTable .tableRow:nth-child(2n) {
  background: #f8f8f8;
}

.basalTable .tableRow .tableValue {
  margin-left: auto;
}

.barItem {
  margin-bottom: 1px;
  transition: height .2s;
}

.eventOverlayCalendar .timeInterval {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  display: flex;
}

.eventOverlayCalendar .timeInterval.active:hover {
  cursor: pointer;
  background: #d6edfc;
}

.eventOverlayCalendar .day {
  width: 25px;
  height: 25px;
  border: 2px solid #0000;
  border-radius: 100px;
  align-items: center;
  margin: auto;
  display: flex;
}

.eventOverlayCalendar .day.active {
  cursor: pointer;
  border-color: #0690ed;
}

.eventOverlayCalendar .day.active:hover {
  background: #d6edfc;
}

.eventOverlayCalendar .eventOverlayCalendarContainer .eventOverlayCalendarItem {
  cursor: pointer;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  align-items: center;
  margin-top: 5px;
  padding: 1px 2px;
  display: flex;
}

.eventOverlayCalendar .eventOverlayCalendarContainer .eventOverlayCalendarItem:hover, .eventOverlayCalendar .eventOverlayCalendarContainer .eventOverlayCalendarItem.highlighted {
  background: #d6edfc;
}

.errorMessage {
  color: #fff;
  z-index: 100;
  background: #b00002;
  border-radius: 8px;
  align-items: center;
  padding: 5px 10px;
  display: flex;
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}

.errorMessage .message {
  margin-right: 10px;
}

.errorMessage .button {
  color: #fff;
  border-color: #fff;
}

/*# sourceMappingURL=index.dcad8fdb.css.map */
